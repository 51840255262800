import { useState } from 'react';

import { useI18n } from 'context/i18n';

import { Select, Modal } from 'antd';

import { CloseMdSVG } from 'components/iconsSVG/close';
import { ChevronSVG } from 'components/iconsSVG/chevron';

import { getCountryLanguages, getCookie, setCookie, getMarket, COOKIES, changeLocaleInUrl, eraseCookie } from 'utils/helpers';
import styles from 'components/layout/style.module.css';

export default function StoreDataRow({ userCS = null, idBag = null, className = '' }) {
  const { locale, t, storeData } = useI18n();
  const market = getMarket(locale);

  const [visible, setVisible] = useState(false);
  const [urlBagRedirect, setUrlBagRedirect] = useState(null);

  const generatedURLBag = (id) => {
    setVisible(true);
    const url = `${window.location.origin}/${locale}/bolsa?hash=${id}`;
    setUrlBagRedirect(url);
  };

  const closeSession = () => {
    eraseCookie(COOKIES.CS_EMPLOYEE_ID);
    eraseCookie(COOKIES.STORE_DATA);
    window.location.assign(`/${locale}/store_ipad`);
  };

  if (storeData?.centro || userCS) {
    return (
      <div className={`flex w-full flex-row justify-between ${className}`}>
        <a href={`/${locale}/home_ipad`} className="min-w-[15%] text-center uppercase md:ml-6">
          <img src="/assets-new/img/store/ipad-logo.png" alt="Main menu" className="max-h-[3em] object-cover" />
        </a>
        <div className={styles.userCScontainer}>
          <img className={styles.infoCVC} src="/assets-new/account-trigger.svg" alt="Info" width={20} height={20} />
          <span className={styles.userLine}> {storeData?.centro || userCS}</span>
          {idBag && (
            <a className={styles.userCSLink} onClick={() => generatedURLBag(idBag)}>
              {' '}
              generar URL
            </a>
          )}

          <Modal
            visible={visible}
            title={t('bolsa', 'url.customer.service', 'URL generada')}
            destroyOnClose={true}
            onCancel={() => setVisible(false)}
            closeIcon={<CloseMdSVG />}
            maskClosable={false}
            footer={null}
            closable={true}
          >
            <p>{urlBagRedirect}</p>
          </Modal>

          <a className={styles.userCSLink} onClick={() => closeSession()}>
            {' '}
            Close session
          </a>
          <div className={styles.countryToSendSelect}>
            {storeData && storeData?.countries ?
              <Select
                suffixIcon={<ChevronSVG />}
                placeholder={t('modal.idiomas', 'select.country', 'Select a country')}
                filterOption={false}
                onChange={(val) => {
                  const searchURL = new URLSearchParams(window.location.search);
                  const langToSend = getCountryLanguages(storeData.countries, val);
                  const countryPriceCookie = getCookie(COOKIES.COUNTRY_PRICE);
                  const countryPriceURL = searchURL.get('country_price');

                  if (countryPriceURL === null) {
                    // no tiene por url, comprueba valores cookie
                    if (countryPriceCookie !== null) {
                      // tiene por cookie, mete el valor en url y no toques nada
                      searchURL.set('country_price', countryPriceCookie);
                    } else {
                      // setea la cookie first time con market
                      searchURL.set('country_price', market);
                      setCookie(COOKIES.COUNTRY_PRICE, market, 1, COOKIES.DOMAIN);
                    }
                  } else {
                    // tiene por url, pisa cookie siempre
                    setCookie(COOKIES.COUNTRY_PRICE, countryPriceURL, 1, COOKIES.DOMAIN);
                  }

                  searchURL.delete('country');
                  searchURL.delete('lng');

                  changeLocaleInUrl(`${langToSend}_${val}`);
                }}
                value={storeData?.countryToSend || market}
                className={`${styles.modalSelect} ${styles.noFloatLabel}`}
                virtual={false}
              >
                {storeData?.countries?.map((country) => (
                  <Select.Option key={country.id} className={styles.selectItem} value={country.id}>
                    <p>{country.name}</p>
                  </Select.Option>
                ))}
              </Select>
            : <Select
                suffixIcon={<ChevronSVG />}
                placeholder={t('modal.idiomas', 'select.country', 'Select a country')}
                className={`${styles.modalSelect} ${styles.noFloatLabel}`}
                virtual={false}
              />
            }
          </div>
        </div>
      </div>
    );
  }

  return null;
}
